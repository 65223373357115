@import "./config";



.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 20vh;
  padding: 0px 90px 0px 90px;
}

.logo-container h2 {
  font-family: $Mukta;
  font-weight: 800;
  color: $darkGray;
  font-size: 1.6rem;
}

.menu-link ul {
  display: flex ;
  align-items: center;
  justify-content: center;
}

.header ul li a {
  font-family: $Roboto;
  padding: 30px;
  color: $gray;
  transition: all 0.3s ease-in-out;
}

.header ul li a:hover {
  color: $grayHover;
}

.hamburger-icon {
  font-size: 40px;
  display: none;
}

.header_social {
  display: flex;
  width: 15vh;
  justify-content: space-between;
}
.header_social li a {
  padding: 0px !important;
}
.header_social li:hover {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.header_social li svg {
  color: $black;
  height: 20px;
  width: 20px;
  transition: all 0.3s ease-in-out;
}

.header_social li svg:hover {
  color: $darkGray;
}

@media only screen and (max-width: 1244px) {
  .header {
    justify-content: space-between;
    padding: 0;
  
    
  }

  .logo-container h2 {
    padding: 30px 30px 30px 30px;
  }

  .menu-link{
    display: none;
  }

  .menu-link-expanded{
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 100;
    top: 0%;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.89);
    transform: translate(0%, 0%);
    & > ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
        & > li {
          padding: 20px;
          & > a{
            font-family: $Mukta;
            color: $white;
            font-size: 30px;
            font-weight: 600;
          }
        }
    }
  
  }

  .bm-close{
    color: $white;
    position: fixed;
    right: 0;
    z-index: 1000 !important;
  }



  .header_social {
    display: none !important;
  }

  .hamburger-icon {
    display: block;
    margin-right: 20px;
    z-index: 2;
    transition: 0.5s ease-in-out;
  }
}

.topImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
@import "./config";

.e-input {
  box-shadow: none !important;
  color: $black !important;
}

.e-input:focus {
  outline: none;
}

.cf-heading-container h2 {
  font-family: $Mukta;
  font-weight: 700;
  font-size: 3rem;
}
.select {
  width: 100%;
  height: 7vh;
  border-radius: 6px;
  color: $gray;
}
.dropdown-container {
  padding: 5px 24px;
}
.e-message-container{
  padding: 5px 24px;
 & > .e-message-input {
  width: 100%;
  height: 20vh;
  padding: 5px;
  border-radius: 6px;
  color: $black ;

}
}


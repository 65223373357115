@import "./config";

.contact-form-container {
  padding: 0%;
}

.cf-lp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cf-rp {
  padding: 50px 0px 30px 0px;
}
.cf-row {
  width: 100%;
  margin: 0;
}

.cf-row-img {
  position: absolute;
  z-index: -2;
  padding: 0px;
  height: 100%;
}
.cf-heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 10px 20px 10px;
}

.cf-heading-lp {
  font-family: $Mukta;
  font-weight: 900;
  font-size: 3rem;
  color: $white;
  background: none;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: center;
}

.cf-heading-cp {
  font-family: $Mukta;
  font-weight: 900;
  font-size: 3rem;
  color: $darkGray;

  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: center;
}

.home-contact-select{
  width: 100%;
    height: 7vh;
    border-radius: 6px;
    color: gray;
    background-color: transparent;
}

.cf-lp img {
  height: 250px;
  width: 420px;
  padding: 45px;
}

.name-input,
.email-input {
  width: 100%;
}

.message-input {
  width: 97%;
  height: 20vh;
  color: $white;
}

.name-input input,
.email-input input {
  width: 100%;
  border-bottom: 0.5px solid $gray;
  border-top: 0.5px solid $gray;
  border-left: 0.5px solid $gray;
  border-right: 0.5px solid $gray;
}

.name-input input,
.email-input input,
.message-input {
  padding: 10px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  color: $white;
  border-radius: 6px;
  background: transparent;
}

.cf-submit-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 30px 0px;
}

.cf-submit-btn {
  padding: 10px;
  margin: 10px;
  width: 40vh;
  font-family: $Mukta;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  border-radius: 6px;
  background: transparent;
  border: 0.5px solid $gray;
  color: $darkGray;
}

.cf-submit-btn:hover {
  transition: all 0.5s ease-in-out;
  background: $black;
  border: 0.5px solid $gray;
  color: $gray;
}

@media only screen and (max-width: 1000px) {
  .name-input input,
  .email-input input {
    width: 100%;
  }

  .cf-lp {
    display: none;
  }

  .cf-row-img {
    height: 100%;
  }

  .cf-heading {
    padding: 0px;
  }

  .cf-rp {
    padding: 0px 0px 0px 0px;
  }
  .cf-heading {
    font-size: 2rem;
    padding: 40px 10px 20px 10px;
  }

  .cf-submit-btn {
    padding: 10px;
    margin: 10px;
    width: 30vh;
    font-size: 15px;
  }
}

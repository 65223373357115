@import "../config";

.post-container {
  & > .post-img-container {
    position: relative;
    & > img {
      width: 100%;
      height: 60vh;
      margin: 10px auto;
      border-radius: 6px;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    }

    & > h2 {
      position: absolute;
      background-color: $white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-family: $Mukta;
      color: $black;
      font-size: 3.5rem;
      font-weight: 800;
      letter-spacing: 2px;
      padding: 18px 30px 18px 30px;
    }
  }

  & > .post-author-container {
    display: flex;
    justify-content: space-between;
    padding: 5px 40px 5px 40px;
    font-family: $Mukta;
    font-weight: 500;
    font-size: 18px;

    & > .author-name-container {
      & > .link {
        margin: 5px;
        font-weight: 700;
      }
    }
  }

  & > .edit-container {
    display: flex;
    justify-content: flex-end;
    padding: 0px 40px 5px 40px;
    & > :first-child {
      font-size: 25px;
      margin: 5px;
      color: green;
      cursor: pointer;
      :hover {
        color: rgba(0, 102, 0, 0.637);
      }
    }
    & > :last-child {
      font-size: 25px;
      margin: 5px;
      color: red;
      cursor: pointer;
      :hover {
        color: rgba(255, 0, 0, 0.377);
      }
    }
  }

  & > .post-desc-container {
    padding: 20px 50px 20px 50px;
    & > p {
      font-family: $Mukta;
    }
    & > .post-desc {
      margin-left: 20px;
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .post-container > .post-img-container > h2 {
    font-size: 1.5rem;
  }

  .post-container {
    & > .post-img-container {
      position: relative;
      & > img {
        width: 100%;
        height: 40vh;
      }
    }

    & > .post-desc-container {
      padding: 20px 20px 20px 20px;
      & > p {
        font-family: $Mukta;
      }
      & > .post-desc {
        margin-left: 20px;
        font-size: 17px;
      }
    }
  }
}

@import "../config";

//--------------------------------- Banner Css--------------------------------

.banner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  background: url("../../images/Blog/blog_bg.jpg");
  background-position: center;
  background-size: 100%;
  filter: brightness(1);
  margin-bottom: 5vh;
  & > :first-child {
    padding: 10px 40px 10px 40px;
    color: $black;
    filter: brightness(1);
    background-color: $white;
    font-family: $Mukta;
    font-weight: 800;
    font-size: 4rem;
    letter-spacing: 4px;
    text-transform: uppercase;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

//--------------------------------- Categories Css--------------------------------

.categories_container {
  padding: 20px;
  & > :first-child {
    font-family: $Mukta;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 4px;
    border-bottom: 2px solid $gray;
  }
  & > :last-child {
    list-style: circle;
    padding: 5px 5px 5px 30px;
    & > * {
      & > * {
        font-family: $Mukta;
        letter-spacing: 0.5px;
        color: $black;
        transition: all 0.3s ease-in-out;
      }
      & > *:hover {
        color: $gray;
      }
    }
  }
}

//--------------------------------- Posts Css--------------------------------

.posts-container {
  display: flex;
  flex-wrap: wrap;
}

//--------------------------------- Post Css--------------------------------

.card {
  width: 24%;
  padding: 10px;
  margin: 20px 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  & > img {
    padding: 5px;
    border-radius: 10px;
  }
  & > :last-child {
    & > * {
      font-family: $Mukta;
    }

    & > :first-child {
      color: $gray;
      text-align: center;
      font-size: 13px;
    }

    & > h5 {
      text-align: center;
      padding-top: 4px;
      font-weight: 700;
    }

    & > .card-text {
      color: $darkGray;
      padding-bottom: 5px;
    }

    & > .time-stamp {
      font-size: 13px;
      color: $gray;
      padding-bottom: 5px;
    }

    & > :last-child {
      background-color: $black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      transition: all 0.3s ease-in-out;
    }

    & > .btn:hover {
      background-color: $darkGray;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .card {
    width: 100%;
  }
}
